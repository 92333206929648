<template>
  <!-- Prélèvement -->
  <div class="parameter-subview">
    <template v-if="isEditable">
      <teleport to="#header-action">
        <Btn
          v-if="helperService.userHasPermission('essaievenement_oedit')"
          class="action-btn"
          icon="create"
          color="white"
          hollow
          text="Modifier"
          :to="{
           name: 'essaiExperimentalTaskParameterEdit',
            params: {
              id: $route.params.id,
              tid: $route.params.tid
            }
        }"
        />
      </teleport>
    </template>
  </div>

  <div class="tabs-material-subtitle">
    <Container>
      <h2>Paramètres</h2>
    </Container>
  </div>

  <div class="key-values key-values--page-bottom">
    <KeyValue
      table
      label="Ordre de saisie"
      :value="fiche?.saisie_ordre_global?.designation"
    />
    <KeyValue
      table
      label="Chemin de notation"
      :value="fiche?.saisie_ordre?.designation"
    />
    <KeyValue
      table
      label="Surface de comptage en m²"
      :value="fiche?.surface_comptage"
    />
    <KeyValue
      table
      label="Nombre de grain échantillon"
      :value="fiche?.nb_grains_comptage_elementaire"
    />
    <KeyValue
      table
      label="Notation à l'aveugle"
      :value="helperService.displayBoolean(fiche?.aveugle)"
    />
  </div>

</template>

<script>

import KeyValue from '@/components/form/KeyValue.vue'
import Btn from '@/components/base/Btn.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'ParameterSubview',
  components: {
    Container,
    Btn,
    KeyValue,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fiche: {},
    }
  },

  mounted() {
    this.getFiche()
  },
  methods: {
    async getFiche() {
      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`)
        .then((response) => {
          const currentNotation = response.data[response.data.length - 1]
          this.fiche = currentNotation
        })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
